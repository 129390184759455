




import Component, { mixins } from "vue-class-component";
import WidgetMixins from "../../../mixins/WidgetMixins";
import DialogMixins from "../../../mixins/DialogMixins";

@Component({
  name: "Disputes"
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {}
